import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import { PhotoTemplate } from '../components/photo-template'
import { GraphQLQuery } from '../types'


type Props = {
  markdownRemark: {
    frontmatter: {
    title: string
    author: string
    date: string
    description: string
    slug: string
    caption?: string
    featuredImage: {
        childImageSharp: GatsbyImageFluidProps
        publicURL: string
    }
    }
    fileAbsolutePath: string
    html: string
  }
}

const PhotoPageTemplate: React.FC<GraphQLQuery<Props>> = ({ data }) => {
    return (
      <Layout postTitle={data.markdownRemark.frontmatter.title}
        postDescription={data.markdownRemark.frontmatter.description}
        isHome={false}
        slug={data.markdownRemark.frontmatter.slug}
        displayArrows={true}
      >
        <PhotoTemplate data={data}/>
      </Layout>
    )
}

export default PhotoPageTemplate

export const query = graphql`
  query($slug: String!){
    markdownRemark(frontmatter: { slug: {eq: $slug} }) {
        frontmatter {
          slug
          title
          description
          author
          featuredImage {
            publicURL 
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
        fileAbsolutePath
    }
  }  
`
