import BackgroundImage from "gatsby-background-image"
import { GatsbyImageFluidProps } from "gatsby-image"
import React, { useEffect, useRef } from "react"

import { getSectionColor } from "../utils/color"
import { cleanContent } from "../utils/util"

export type PhotoData = {
  markdownRemark: {
    html: string
    frontmatter: {
      author: string
      description: string
      title: string
      featuredImage: {
        childImageSharp: GatsbyImageFluidProps
      },
      slug: string
    }
  }
}

export type PhotoTemplateProps = {
  data: PhotoData
}

export const PhotoTemplate = ({
  data: {
    markdownRemark: {
      html: content,
      frontmatter: { author, description, title, featuredImage, slug }
    }
  }
}: PhotoTemplateProps) => {
  // TODO: Get rid of this

  return (
    <section
      style={{
        objectFit: 'cover'
      }}>
      <BackgroundImage
        Tag="div"
        className="h-screen w-full z-20"
        fluid={featuredImage.childImageSharp.fluid}>
        <div className="max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center">
          <div className="max-w-3xl mx-auto bg-gray-50 w-full rounded-lg">
            <div className="flex flex-col justify-center items-center p-4">
              <p className="text-gray-800 font-bold text-xl md:text-4xl">
                {title}
              </p>
              <p className="italic pt-6 text-gray-500 text-sm md:text-base">
                {description}
              </p>
              <p className="font-semibold text-gray-700 pt-6 text-sm md:text-base">
                {author}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="max-w-4xl mx-auto pt-8 pb-8">
        <div className="px-4 md:px-0">
          <div className="text-md leading-8 font-normal px-8 md:px-8 space-y-4" dangerouslySetInnerHTML={{__html: content }}>
          </div>
        </div>
      </div>
    </section>
  )
}